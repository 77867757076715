<template>
    <p class="block-title"><slot></slot></p>
</template>

<script>
export default {
    name: "BlockTitle"
}
</script>

<style scoped>
    .block-title {
        margin: 0;
        padding: 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
