<template>
    <div>
        <block-title>绑定后即可通过手机号登录</block-title>
        <van-form @submit="onSubmit" validate-first>
            <van-field
                type="tel"
                v-model="form.phone"
                name="phone"
                label="手机号"
                placeholder="请输入手机号"
                :rules="[{ required: true, message: '请输入手机号' }]"
            />
            <van-field
                type="digit"
                v-model="form.code"
                center
                clearable
                name="code"
                label="验证码"
                placeholder="请输入验证码"
                :rules="[{ required: true, message: '请输入验证码' }]"
            >
                <template #button>
                    <van-button
                        native-type="button"
                        @click="handleSendSms"
                        size="small"
                        type="primary"
                        :loading="sending"
                        loading-size="16px"
                        loading-text="发送中..."
                        :disabled="send"
                    >{{ buttonText }}</van-button>
                </template>
            </van-field>
            <div style="margin: 16px;">
                <van-button
                    round
                    block
                    type="info"
                    native-type="submit"
                    :loading="submitting"
                    loading-size="16px"
                    loading-text="提交中..."
                    :disabled="submitting"
                >提交</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
import BlockTitle from '@/components/BlockTitle'
import { bindPhone, sendBindPhoneSms } from '@/api/user'
import { LOGIN_USER } from '@/utils/constants'

export default {
    name: 'BindPhone',
    components: { BlockTitle },
    data () {
        return {
            sending: false,
            send: false,
            submitting: false,
            waitingTimer: null,
            waitingTime: 60,
            form: {
                phone: null,
                code: null
            }
        }
    },
    computed: {
        buttonText () {
            if (this.send) {
                return this.waitingTime + '秒后再发送.'
            }
            return '发送验证码'
        }
    },
    watch : {
        waitingTime (val) {
            if (val <= 0) {
                this.send = false
                this.waitingTime = 60
                clearInterval(this.waitingTimer)
            }
        }
    },
    methods: {
        handleSendSms () {
            const reg = /^1[3456789]\d{9}$/

            const valid = reg.test(this.form.phone)
            if (!valid) {
                this.$notify({ type: 'warning', message: '手机号不合法.' })
                return
            }
            if (this.$ls.get('sms_lock')) {
                this.$notify({ type: 'warning', message: '发送太频繁, 请稍后再试.' })
                return
            }
            this.sending = true
            sendBindPhoneSms({ phone: this.form.phone }).then(res => {
                this.$notify({ type: 'success', message: res.message })
                this.$ls.set('sms_lock', 1, 60 * 1000)
                this.send = true
                this.waitingTimer = setInterval(() => {
                    if (this.waitingTime > 0) {
                        this.waitingTime--
                    }
                }, 1000)
            }).finally(() => {
                this.sending = false
            })
        },
        onSubmit() {
            this.submitting = true
            bindPhone(this.form).then(res => {
                this.$ls.set(LOGIN_USER, res.data);
                this.$notify({ type: 'success', message: '操作成功' })
                this.$emit('ok')
                this.$router.push({ path: '/user' })
            }).finally(() => {
                this.submitting = false
            })
        }
    }
};
</script>

<style lang="less">
    .action-icon {
        margin-left: 5px;
    }
</style>
